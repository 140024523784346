<template>
    <div class="w-full mx-auto px-4 py-12 text-center bg-white">
        <RegistrationDownload/>
    </div>
    
</template>

<script>
import RegistrationDownload from '@/components/content/RegistrationDownload.vue'

export default {
    name: 'DownloadRegistration',
    components:{
        RegistrationDownload
    }

}
</script>

<style>

</style>