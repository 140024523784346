<template>
  <div class="flex h-screen bg-gray-200 font-roboto">
    <div class="flex-1 flex flex-col overflow-hidden">
      <Header />
      <main class="flex-1 overflow-x-hidden overflow-y-auto bg-white">
          <slot />
          <Footer/>
      </main>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Header from './Header.vue'
import Footer from './Footer.vue'

export default defineComponent({
    components: {
        Header, Footer
    },
});
</script>
