<template>
  <News/>
</template>

<script>
import News from '@/components/content/news/News.vue'
export default {
    name :'NewsView',
    components:{News}

}
</script>

<style>

</style>