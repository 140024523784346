<template>
  <Education/>
</template>

<script>
import Education from '@/components/content/education/Education.vue'
export default {
    name:'EducationView',
    components:{Education}

}
</script>

<style>

</style>