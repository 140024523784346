<template>
    <Contact/>
</template>

<script>
import Contact from '@/components/content/Contact.vue'
export default {
    name:'ContacView',
    components:{Contact}

}
</script>

<style>

</style>