<template>
   <History/>
</template>

<script>
import History from '../components/content/History.vue'
export default {
    name:'HistoryView',
    components:{History}

}
</script>

<style>

</style>