import { defineComponent } from 'vue';
<template>
    <div class="w-full bg-white py-12">
        <div v-if="data && !data.user" class="w-full mx-auto px-4 py-12 text-center">
            <h2 class="text-3xl font-bold text-center mb-8">Descarga de Matrícula</h2>
            <div class="max-w-md mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
                <form @submit.prevent="submitForm" class="p-6">
                    <div class="mb-6">
                        <label for="documento" class="block text-gray-700 font-bold mb-2">Número de Documento (sin puntos)</label>
                        <input
                        type="number"
                        id="documento"
                        placeholder="Ingrese solo números (sin puntos)"
                        v-model="documento"
                        class="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                        min="0"
                        >
                    </div>
                    <div class="text-center cursor-pointer">
                        <div @click="checkEnrollment" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-6 rounded-full shadow-lg">
                        Generar Matrícula
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div v-else class="w-full mx-auto px-4 py-12 text-center">
            <div class="max-w-md mx-auto" v-if="data.user && (data.hasEnrollmentValid == 'yes' || data.error == 'inactive')">
                <h2 class="text-3xl font-bold text-center mb-8">Datos</h2>
                <div class="text-start">
                    <p class="m-2">
                        Nombre: {{ data.user.firstname }}<br>
                    </p>
                    <p class="m-2">
                        Apellido: {{ data.user.lastname }}<br>
                    </p>
                    <p class="m-2">
                        Número de Documento: {{ data.user.dni }}<br>
                    </p>
                    <p v-if="!data.error" class="w-full text-center py-6">
                        <a
                        @click="cleanData"
                        :href="'https://api-dev-copef.vicomser.com/api/enrollment-download?dni='+ documento"
                        target="_blank"
                         class="block w-full cursor-pointer bg-red-500 hover:bg-red-700 text-white font-bold  py-2 px-6 rounded-full shadow-lg text-center"
                        >Descargar Matrícula</a>
                    </p>
                    <p v-else class="p-2">
                        Su matrícula se encuentra Inactiva.
                        <p @click="cleanData" class="cursor-pointer bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-6 m-4 rounded-full shadow-lg text-center">    
                            Volver
                        </p>
                    </p>
                </div>
            </div>
            <!-- <p class="text-4xl font-bold text-center mb-8">No tienes matrícula</p> -->
            <div class="max-w-md mx-auto bg-white rounded-lg shadow-lg overflow-hidden"></div>
        </div>

        <!-- ComponentsConfirAction : Modal de Confirmacion -->
        <ConfirmAction
        :isVisible="isvisibleModal"
        :btnCancel="false"
        @confirm="cleanData"
        @cancel="cleanData"
        title='El DNI no existe'
        action='Guardar'
        description="El dni ingresado no corresponde a un profesor con matricula"
        />
    </div>
</template>

<script>
import ConfirmAction from '../commons/ConfirmAction.vue'

export default {
    name: 'RegistrationDownload',
    components: { ConfirmAction },
    data() {
    return {
        documento: '',
        data: {},
        isvisibleModal: false
    };
  },
  methods: {
    cleanData() {
        this.documento = '',
        this.data = {}
        this.isvisibleModal = false
    },
    checkEnrollment(download = null) {

        if(this.documento){
            let parameters
            if (!download) {
                parameters = `?dni=${this.documento}`
            } else {
                parameters = `?dni=${this.documento}&validate=1`
            }
            this.$http.get(`/enrollment-download${parameters}`)
            .then(resp => {
                const data = resp.data.data
                if (data.hasEnrollmentValid && data.hasEnrollmentValid == 'yes') {
                    this.data = data
                }
            })
            .catch(error => {
                const {data} = error.response

                if(data.data.error){
                    const error = data.data.error
                    if (error) {
                        switch (error) {
                            case 'dniNotFound':
                                console.log('No existe dni')
                                this.isvisibleModal = true
                                break;
                            case 'withoutDni':
                                console.log('Ingrese DNI')
                                break;
                            case 'inactive':
                                console.log('Inactivo')
                                this.data = data.data
                                break;
                            default:
                                break;
                        }
                    }
               }

            })
        }
     },

    }
}

</script>
