<template>
  <div>
      <div class="bg-white max-w-6xl mx-auto py-6">
        <div class="container px-4 sm:px-8 flex flex-col">
            <h2 class="text-3xl font-bold text-gray-500 mb-2">¡Bienvenidos a Nuestra Organización!</h2>
            <p class="text-base lg:text-lg text-gray-500 font-medium leading-relaxed ">Somos una organización de profesionales comprometidos con la excelencia y la innovación.</p>
        </div>
      </div>
    <!-- Línea divisoria -->
    <div class="container mx-auto px-4">
      <hr class="border-gray-300 my-4">
    </div>

    <!-- Contenido principal -->
    <div class="py-2">
      <div class="px-4 max-w-6xl mx-auto lg:px-8 flex flex-col lg:flex-row lg:space-x-8">
        <!-- Sección de texto -->
        <div class="lg:w-1/2 mb-8 lg:mb-0">
          <h1 class="pb-2 text-xl lg:text-1xl font-bold text-gray-700 leading-tight uppercase tracking-wide">{{ post.title }}</h1>
          <h2 class="py-2 text-sm font-bold text-gray-800 mb-4 border-b-2 border-red-500 inline-block">Sobre Nosotros</h2>
          <p v-html="post.content" class="text-base lg:text-lg text-gray-700 font-light leading-relaxed"></p>
        </div>
        <!-- Sección de imagen -->
        <div class="lg:w-1/2 flex justify-center items-center">
          <div class="relative w-80 h-80 lg:w-140 lg:h-140">
            <img
              v-if="post.post_image && post.post_image.length > 0"
              :src="'https://api-dev-copef.vicomser.com/storage/' + post.post_image[0].url + post.post_image[0].image.name"
              :alt="post.post_image[0].image.name"
              class="absolute inset-0 w-full h-full rounded-full object-cover shadow-lg">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>






<script>
export default {
  name:'History',
    data(){
        return{
            post: []
        }
    },
    created(){
        this.getPostId(3)
    },
    methods:{
        getPostId (postId){
            this.$http.get(`/posts/${postId}`)
            .then( resp => {
                this.post = resp.data.data
            })
              .catch(error => console.error(error));
        }
    }
}
</script>
<style>
</style>
