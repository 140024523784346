<template>
   <Board/>
</template>

<script>
import Board from '../components/content/Board.vue'
export default {
    name: 'BoardView',
    components:{
        Board
    }

}
</script>

<style>

</style>