<template>
  <div class="">
    <div class="bg-white max-w-6xl mx-auto py-6">
        <div class="container px-4 sm:px-8 flex flex-col">
          <h2 class="text-3xl font-bold text-gray-500 mb-2">Nuestra Comision Directiva</h2>
          <p class="text-base lg:text-lg text-gray-500 font-medium leading-relaxed ">Conocenos quienes somos</p>
        </div>
    </div>
    <div class="py-2">
      <div class="container  max-w-6xl mx-auto px-4 lg:px-8 flex flex-col lg:flex-row lg:space-x-8">
        <div v-for="(b, index) in board " :key="index" class="lg:w-1/2 mb-8 lg:mb-0">
        <div class="bg-red-500"></div>
          <h3 class="text-xl lg:text-1xl font-bold text-gray-600 mb-2 border-b-2 border-red-500 inline-block pb-2 ">{{ b.title }}</h3>
          <p v-html="b.content" class="text-base  text-gray-700 font-light leading-relaxed"></p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
    name:'Board',
    data(){
        return{
            board :[]
        }
    },
    created(){
        this.getBoard()
    },
    methods:{
        getBoard(){
            this.$http.get('/posts-complete?post_category_id=11')
            .then(resp=> {
                console.log(resp.data.data.data);
                this.board=resp.data.data.data
            })
            .catch(error=> console.log(error))
        }
    }
}
</script>

<style>

</style>