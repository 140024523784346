import axios from 'axios';

  const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
  })

  // //interceptor to add token to header
  // instance.interceptors.request.use(config => {
  //   config.headers.Authorization = 'MAu0XMBxXd'; //localStorage.getItem('token');
  //   return config;
  // }, error => {
  //   return Promise.reject(error);
  // });

  // instance.interceptors.response.use(response => {
  //   return response;
  // }, error => {
  //   return Promise.reject(error);
  // });

export default instance;