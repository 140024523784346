
<template>
    <div class="max-w-4xl mx-auto lg:px-auto">

        <router-link to="/news" class="inline-block py-6">
            <div class="bg-red-500 text-white text-sm font-bold uppercase px-4 py-2 mb-2 transform -skew-x-6">
                <i class="fas fa-chevron-left"></i>
                Noticias
            </div>
        </router-link>
        <div class="px-4 md:px-0">
            <h1 class="text-3xl lg:text-3xl text-gray-800 font-bold mb-4">{{ post.title }}</h1>
            <hr class="border-gray-300 my-4">
            <div class="flex justify-between my-4">
                <p class="text-xs text-gray-400">Publicado el {{ formatDate(post.created_at) }}</p>
                <i class="far fa-heart text-gray-400"></i>
            </div>
        </div>
        <img
        v-if="post.post_image && post.post_image.length > 0"
        :src="'https://api-dev-copef.vicomser.com/storage/' + post.post_image[0].url + post.post_image[0].image.name"
        :alt="post.post_image[0].image.name"
        class="h-100 md:h-120 xl:h-120 lg:rounded-lg shadow-md mb-4"
        >
        <p class="px-4 md:px-0 text-base lg:text-lg text-gray-800 leading-relaxed" v-html="post.content"></p>
        <hr class="border-gray-300 my-4">
        <CardNews/>
    </div>
</template>

<script>
import CardNews from '@/components/content/news/CardNews.vue';

export default {
    components:{
        CardNews
    },
    data(){
        return{
            post:{}
        }
    },
    created() {
    const postId = this.$route.params.id;
    this.getPost(postId);
    },
methods: {
        getPost(postId) {
            this.$http.get(`/posts/${postId}`)
            .then(resp => {
                this.post = resp.data.data;
            })
            .catch(error => console.error(error));
        },
        formatDate(StringDate){
            const date  =  new Date (StringDate)
            return date.toLocaleString()
        },
    }
}
</script>

<style>

</style>