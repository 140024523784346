<template>
  <div>
      <div class="bg-white max-w-6xl mx-auto py-6">
        <div class="container px-4 sm:px-8 flex flex-col">
          <h2 class="text-3xl font-bold text-gray-500 mb-2"><i class="fas fa-graduation-cap mr-2"></i> Proceso de Matriculación</h2>
          <p class="text-base lg:text-lg text-gray-500 font-medium leading-relaxed ">Aquí encontrarás toda la información necesaria para matricularte en nuestros programas.</p>
        </div>
    </div>
    <!-- Contenido principal -->
    <div class="bg-white py-2">
      <hr class="border-gray-300 py-2">
      <div class="px-4 lg:px-8  max-w-6xl mx-auto">
        <!-- Sección de pasos de matriculación -->
        <div class="mb-8">
          <h2 class="text-xl lg:text-2xl font-bold text-gray-600 mb-2 border-b-2 border-gray-200 inline-block pb-2 ">
            <i class="fas fa-list-alt text-red-500"></i> {{ registration.title }}
          </h2>
          <p v-html="registration.content" class="text-base lg:text-lg text-gray-700 font-light leading-relaxed"></p>
        </div>
        <!-- Sección de contacto -->
        <div class="mb-8">
          <h2 class="text-xl lg:text-2xl font-bold text-gray-600 mb-2 border-b-2 border-gray-200 inline-block pb-2">
            <i class="fas fa-envelope text-red-500"></i> Contacto
          </h2>
          <p class="text-base lg:text-lg text-gray-700 font-light leading-relaxed">
            Si tienes alguna pregunta o necesitas asistencia durante el proceso de matriculación, no dudes en contactarnos:
          </p>
          <p class="text-sm text-gray-500"><strong>Email:</strong> copefsalta@gmail.com</p>
          <p class="text-sm text-gray-500">
            <strong>WhatsApp:</strong>
            <a href="https://wa.me/5493875802458" class="text-gray-500 hover:text-green-500" target="_blank">
              <i class="fab fa-whatsapp ml-2"></i> (0387) 5802458
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default({
  name: 'Registration',
  data() {
    return {
      registration: {}
    };
  },
  created() {
    this.getRegistration(15);
  },
  methods: {
    getRegistration(postId) {
      this.$http.get(`/posts/${postId}`)
        .then(resp => {
          this.registration = resp.data.data;
        })
        .catch(error => console.error(error));
    }
  },
});
</script>

<style >

</style>
