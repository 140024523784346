<template>
    <Laws/>
</template>

<script>
import Laws from '@/components/content/Laws.vue'
export default {
    components : { Laws}
}
</script>

<style>

</style>