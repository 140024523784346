<template>
  <div class="bg-gray-700 border-t border-gray-200 py-3">
    <div class="container mx-auto lg:flex lg:justify-between lg:space-x-8">

      <div class="lg:w-1/3 mb-4 lg:mb-0 flex justify-center items-center">
        <img src="@/assets/logo-copef-white.png" alt="Logo CopefSalta" class="h-12 lg:h-8 mr-2">
        <p class="text-gray-100 font-semibold text-lg lg:text-2xl">COPEFSALTA</p>
      </div>
      <div class="lg:w-1/3 mb-4 lg:mb-0 ">
          <div class="  lg:flex justify-between">
              <nav class="text-center mb-4">
                <ul class="flex flex-col justify-center  lg:text-left">
                  <li class="mb-2">
                    <router-link to="/education" class="block p-2 text-gray-100 hover:text-red-600 transition duration-300">Capacitaciones</router-link>
                  </li>
                  <li class="mb-2">
                    <router-link to="/leyes" class="block p-2 text-gray-100 hover:text-red-600 transition duration-300">Leyes y Normas</router-link>
                  </li>
                </ul>
              </nav>
              <nav class="text-center mb-4">
                <ul class="flex flex-col justify-center lg:text-left">
                  <li class="mb-2">
                    <router-link to="/news" class="block p-2 text-gray-100 hover:text-red-600 transition duration-300">Noticias</router-link>
                  </li>
                  <li class="mb-2">
                    <router-link to="/contact" class="block p-2 text-gray-100 hover:text-red-600 transition duration-300">Contactanos</router-link>
                  </li>
                </ul>
              </nav>
          </div>
      </div>
      <div class="lg:w-1/3 mb-4 lg:mb-0 lg:flex justify-center">
        <ul class="flex justify-center gap-3 text-lg items-center">
          <li>
            <a href="#" class="social-icon flex items-center justify-center w-10 h-10 rounded-full border-2 border-gray-100 text-gray-100 hover:text-gray-300 hover:border-gray-300">
              <i class="fab fa-facebook"></i>
            </a>
          </li>
          <li>
            <a href="#" class="social-icon flex items-center justify-center w-10 h-10 rounded-full border-2 border-gray-100 text-gray-100 hover:text-gray-300 hover:border-gray-300">
              <i class="fab fa-twitter"></i>
            </a>
          </li>
          <li>
            <a href="#" class="social-icon flex items-center justify-center w-10 h-10 rounded-full border-2 border-gray-100 text-gray-100 hover:text-gray-300 hover:border-gray-300">
              <i class="fab fa-linkedin"></i>
            </a>
          </li>
          <li>
            <a href="#" class="social-icon flex items-center justify-center w-10 h-10 rounded-full border-2 border-gray-100 text-gray-100 hover:text-gray-300 hover:border-gray-300">
              <i class="fab fa-whatsapp"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="bg-gray-800 text-center text-gray-100 p-2 mx-auto">
    <p class="text-xs">© 2024 CopefSalta. Todos los derechos reservados.</p>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
