<template>
   <Registration/>
</template>

<script>
import Registration from '../components/content/Registration.vue'
export default {
    name: 'RegistrationView',
    components:{
        Registration
    }

}
</script>

<style>

</style>