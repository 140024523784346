<template>
    <div class="bg-white  max-w-6xl mx-auto py-6">
        <div class="container px-4 sm:px-8 flex flex-col">
        <h2 class="text-3xl font-bold text-gray-500 mb-2">¡Contáctanos!</h2>
        <p v-if="false" class="text-base lg:text-lg text-gray-500 font-medium leading-relaxed ">Estamos aquí para ayudarte. Por favor, completa el formulario a continuación y nos pondremos en contacto contigo lo antes posible.</p>
        </div>
    </div>
    <div class="bg-gray-100  flex items-center justify-center py-1">
        <div class="bg-white p-6 rounded-lg shadow-lg w-full max-w-4xl flex flex-wrap">
            <div v-if="false" class="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                <h2 class="text-2xl font-bold text-gray-800 mb-6 text-center">Formulario de Registro</h2>
                <form @submit.prevent="submitForm">
                    <div class="mb-4">
                        <label for="name" class="block text-gray-700 font-bold mb-2">Nombre Completo</label>
                        <input
                            type="text"
                            id="name"
                            v-model="form.name"
                            placeholder="Ingrese su nombre completo"
                            class="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-600 placeholder-opacity-50"
                            required
                        />
                    </div>
                    <div class="mb-4">
                        <label for="email" class="block text-gray-700 font-bold mb-2">Correo Electrónico</label>
                        <input
                            type="email"
                            id="email"
                            v-model="form.email"
                            placeholder="Ingrese su email ejemplo@gmail.com"
                            class="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-600 placeholder-opacity-50"
                            required
                        />
                    </div>
                    <div class="mb-4">
                        <label for="subject" class="block text-gray-700 font-bold mb-2">Asunto</label>
                        <input
                            type="text"
                            id="subject"
                            v-model="form.subject"
                            placeholder="Escribir el asunto"
                            class="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-600 placeholder-opacity-50"
                        />
                    </div>
                    <div class="mb-4">
                        <label for="address" class="block text-gray-700 font-bold mb-2">Mensaje</label>
                        <textarea
                            id="address"
                            v-model="form.mensaje"
                            placeholder="Escribir un mensaje detallando el problema"
                            class="w-full px-3 py-2 border border-gray-300 rounded-lg text-sm text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-600 placeholder-opacity-50"
                            rows="3">
                        </textarea>
                    </div>
                        <div class="flex items-center">
                            <button
                                type="submit"
                                class="bg-blue-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
                                >
                                Registrarse
                            </button>
                        </div>
                </form>
            </div>

            <div class="w-full lg:w-1/2 px-4">
                <h2 class="text-2xl font-bold text-gray-800 mb-6 text-center">Contáctese con Nosotros</h2>
                <p class="mb-4 text-base lg:text-lg text-gray-700 font-light leading-relaxed">
                    Si tiene alguna inquietud o necesidad no dude en ponerse en contacto con nosotros, a través de todos los medios que ponemos a su alcance.
                    Estamos dispuestos a ayudarle en el problema que usted tenga.
                </p>
                <div class="mb-4">
                    <h3 class="text-base lg:text-lg text-gray-700 font-sm leading-relaxed">Dirección:</h3>
                    <p class="text-base lg:text-lg text-gray-700 font-light leading-relaxed">
                        <i class="fas fa-map-marker-alt mr-2"></i>Psje. Talapampa 763 B. San Cayetano
                    </p>
                </div>
                <div class="mb-4">
                    <h3 class="text-base lg:text-lg text-gray-700 font-sm leading-relaxed">Teléfono</h3>
                    <p class="text-base lg:text-lg text-gray-700 font-light leading-relaxed">
                        <i class="fas fa-phone-alt mr-2"></i>
                        Cel. (0387) 5802458
                    </p>
                </div>
                <div class="mb-4">
                    <h3 class="text-base lg:text-lg text-gray-700 font-sm leading-relaxed">
                        Email:</h3>
                    <p class="text-base lg:text-lg text-gray-700 font-light leading-relaxed">
                        <i class="fas fa-envelope mr-2"></i>
                        copefsalta@gmail.com
                    </p>
                </div>
                <div class="mb-4">
                    <h3 class="text-base lg:text-lg text-gray-700 font-sm leading-relaxed">Horario de atención:</h3>
                    <p class="text-base lg:text-lg text-gray-700 font-light leading-relaxed">
                        <i class="fas fa-clock mr-2"></i>
                        Lunes a viernes 8.30 a 12.30 / 14 a 18 hrs.
                    </p>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
            name: '',
            email: '',
            mensaje: '',
            subject: '',
            }
        }
    },
    methods: {
        submitForm() {

            console.log(this.form);
        }
    }
};
</script>

<style>

</style>
