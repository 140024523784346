<template>
    <div class="bg-white max-w-6xl mx-auto py-6">
        <div class="container px-4 sm:px-8 flex flex-col">
            <h2 class="text-3xl font-bold text-gray-500 mb-2">Recursos legales para vos</h2>
            <p class="text-base lg:text-lg text-gray-500 font-medium leading-relaxed ">Encuentra aquí toda la información legal que necesitas.</p>
        </div>
    </div>
    <div class="mx-auto p-2 bg-gray-100 shadow-lg rounded-lg">
        <div class="container  max-w-6xl mx-auto ">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-items-center">
            <div v-for="(item, index) in leyes" :key="index" :id="'ley-' + index" class="inline-block bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                <div class="p-4">
                <div class="flex flex-col items-center justify-center mb-4">
                    <i class="fas fa-book text-blue-500 text-4xl mb-4 text-red-500"></i>
                    <h3 class="text-lg font-semibold text-gray-700 mb-2">{{ item.title }}</h3>
                    <p class="text-base  text-gray-800 font-light leading-relaxed" v-html="item.content"></p>
                </div>
                <div class="w-full" v-for="(file, i) in documents[index]['docs']" :key="file+i">
                    <a :href="'/files/' + file.file" class="text-red-400 flex items-center justify-start hover:text-blue-600 mb-2">
                    <i class="fas fa-file-pdf mr-2"></i>
                    <span>{{ file.title }}</span>
                    </a>
                    <p class="text-xs text-gray-500">Descargar {{ file.title }}</p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name:'Leyes',
        data(){
            return{
                    leyes: [],
                    documents: [
                        {id:1, docs: [{title: 'Ley N 7233' , file: "ley_7233.pdf"}, ]},
                        {id:2, docs:[
                                    {title: ' Régimen de Licencias, Justificaciones y Franquicias.' , file: "regimen_licencias.pdf"},
                                    {title: 'Reglamento Gral. Para la Enseñanza Media, Técnica y Superior.' , file: "reglamento_poli.indd.pdf"},
                                    {title: 'Reglamento General de Escuelas.' , file: "reglamento_general_escuelas.pdf"},
                                    {title: 'Régimen de Valoración de Antecedentes.' , file: "regimen_de_valoracion_de_antecedentes.pdf"}]
                        },
                        {id:3, docs: [{title: 'Reglamento de Licencias' , file: "reglamento_de_licencias.pdf"},{title: 'Reglamento Interno' , file: "reglamento_interno.pdf"}]},
                        {id:4, docs: [{title: 'Ley Provincial de Educación 7.546.' , file: "ley_provincial_de_educacion_7546.pdf"} ,{title: 'Ley Nacional de Educación 26.206.' ,file: 'ley_nacional_.doc.pdf'}]},
                        {id:5, docs: [{title: 'Decreto Nº 810.' , file: "decreto_810.pdf"},{title: 'NAP Unificado' , file:'nap_unido.pdf'}]},
                    ]
                }
        },
        created(){
                this.getReglament()
            },
        methods:{
            getReglament(){
                    this.$http.get('/posts-complete?post_category_id=2')
                    .then( resp => {
                        console.log(resp.data.data.data);
                        const onlyforpost= resp.data.data.data
                        this.leyes = onlyforpost.filter((post,i)=>i <= 4);
                        console.log("los primeros", this.leyes);
                    })
                    .catch(error => {
                    console.error('Error al realizar la solicitud :', error);
                });
            }
        }
    }
</script>

<style>

</style>